angular.module('maxfonder').factory('DiskService', function ($rootScope, $q, $http, $resource, $filter, BlobService, MaxfonderService) {

    var resource = $resource(null, null, {
        'getNonDiskDepositsForConversion': {
            url: '/ps/api/maxfonder/disk/nondiskdepositsforconversion',
            method: 'GET',
            isArray:true
        },
        'getDiscFees': {
            url: '/ps/api/maxfonder/disk/fees',
            method: 'GET',
            isArray: true
        },
        'registerDiskNew': {
            url: '/ps/api/maxfonder/disk/registration-newdeposit/signed',
            method: 'POST'
        },
        'registerDiskExisting': {
            url: '/ps/api/maxfonder/disk/registration-existing/signed',
            method: 'POST'
        }
    })

    var service = {};

    service.MODE_NEW = "new";
    service.MODE_EXISTING = "existing";

    service.DEFAULT_VALIDATION_ERROR_TYPE = "invalid";

    service.getNonDiskDepositsForConversion = function () {
        return resource.getNonDiskDepositsForConversion();
    }

    service.getDiscFees = function () {
        return resource.getDiscFees();
    }

    service.getBanks = function () {
        return MaxfonderService.getBanks();
    }

    service.getBrokersAndCodes = function() {
        return MaxfonderService.getBrokersAndCodesFunc();
    }

    service.mapBrokerRow = function(name, code){
        return {name: name, code: code, label: (name + ' (' + code + ')')};
    }

    service.getPortfolioTypes = function() {
        return MaxfonderService.getPortfolioTypes();
    }

    service.getPortfoliosOfType = function(portfolioTypeId) {
        return MaxfonderService.getPortfoliosOfType(portfolioTypeId);
    }

    service.getSavedRegistration = function () {
        if (sessionStorage.maxfonderDISKRegistration) {
            var savedRegistration = service.createRegistration(JSON.parse(sessionStorage.maxfonderDISKRegistration));
            if (savedRegistration.ssn === $rootScope.user.ssn) {
                return savedRegistration;
            }
        }
        return null;
    }

    service.createRegistration = function(savedRegistration) {
        var registration;
        if (savedRegistration) {
            registration = savedRegistration;
        } else {
            registration = {
                ssn: $rootScope.user.ssn,
                mode: service.MODE_NEW,
            };
        }
        return registration;
    }

    service.saveRegistration = function (registration) {
        sessionStorage.maxfonderDISKRegistration = JSON.stringify(registration);
    }

    service.deleteRegistration = function () {
        sessionStorage.removeItem('maxfonderDISKRegistration');
    }

    service.send = function (registration, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {
        var signatureWithToken = {
            signatureToken: signatureToken,
            signature: {
                signature: signature,
                visibleData: signatureVisibleData
            }
        };
        var signatureReference = {
            bankIdLogEntryId: bankIdLogEntryId,
            ipAddress: ipAddress
        };

        return service.sendRegistration(registration, signatureWithToken, signatureReference);
    }

    service.sendRegistration = function (registration, signatureWithToken, signatureReference) {
        if ( registration.mode === service.MODE_NEW ){
            var request = mapRegistrationAndPortfolioManagementRequest(registration, signatureWithToken, signatureReference);
            var deferred = $q.defer();
            resource.registerDiskNew(request).$promise.then(
                function (response) {
                    deferred.resolve(response.documentId);
                }, function (error) {
                    console.log(error);
                    deferred.reject();
                }
            );
            return deferred.promise;
        } else if ( registration.mode === service.MODE_EXISTING ){
            var request = mapPortfolioManagementRequest(registration, signatureWithToken, signatureReference);
            var deferred = $q.defer();
            resource.registerDiskExisting(request).$promise.then(
                function (response) {
                    deferred.resolve(response.documentId);
                }, function (error) {
                    console.log(error);
                    deferred.reject();
                }
            );
            return deferred.promise;
        } else {
            // should never happen
            throw "Bad request mode."
        }
    }

    //Validation
    function validationError(field, errorType=service.DEFAULT_VALIDATION_ERROR_TYPE) {
        return {
            field: field,
            errorType: errorType
        };
    }

    service.getValidationErrorForField = function(validationErrors, fieldName, errorType=null) {
        if (validationErrors && validationErrors.length) {
            for (var i = 0; i < validationErrors.length; i++) {
                if (validationErrors[i].field === fieldName && (errorType === null || validationErrors[i].errorType === errorType)) {
                    return validationErrors[i];
                }
            }
        }
        return null;
    }

    service.newValidationError = function(field, errorType) {
        return validationError(field, errorType);
    }

    service.validateDepositData = function(registration) {
        var errors = [];

        if (!service.depositTypeDataCorrect(registration.depositType)) {
            errors.push(validationError('depositType'));
        }

        if (registration.mode === service.MODE_NEW){
            if (!service.bankInfoCorrect(registration.bank)) {
                errors.push(validationError('bank'));
            }
            if (!service.bankAccountClearingNumberCorrect(registration.bankAccountClearingNumber)) {
                errors.push(validationError('bankAccountClearingNumber'));
            }
            if (!service.bankAccountNumberCorrect(registration.bankAccountNumber)) {
                errors.push(validationError('bankAccountNumber'));
            }
        } else if (registration.mode === service.MODE_EXISTING){
            if (!registration.depositNumber || registration.depositNumber.length === 0) {
                errors.push(validationError('depositNumber'));
            }
        }

        return errors;
    }

    service.validatePortfolioManagementRegistrationData = function(registration) {
        var errors = [];

        var portfolio = registration.portfolio;
        if (!portfolio ||
            !service.hasValue(portfolio.portfolioId) ||
            !service.hasLength(portfolio.portfolioName) ||
            !service.hasValue(portfolio.portfolioTypeId) ||
            !service.hasLength(portfolio.portfolioTypeName) ||
            !service.hasValue(portfolio.portfolioTypeInstIntCode)) {
            errors.push(validationError('portfolio'));
        }
        if(!service.hasValue(registration.monthlyAmount) && !service.hasValue(registration.initialAmount)){
            errors.push(validationError('monthlyAmount'));
            errors.push(validationError('initialAmount'));
        } else {
            if (service.hasValue(registration.monthlyAmount)) {
                if (!service.isPositiveInteger(registration.monthlyAmount)) {
                    errors.push(validationError('monthlyAmount'));
                }
                if (!service.yearMonthCorrectFormat(registration.monthlyStartMonth) ||
                    !service.yearMonthOnOrAfterCurrentMonth(registration.monthlyStartMonth)) {
                    errors.push(validationError('monthlyStartMonth'));
                }
                if (service.hasValue(registration.monthlyEndMonth) &&
                    (!service.yearMonthCorrectFormat(registration.monthlyStartMonth) ||
                        !service.yearMonthCorrectFormat(registration.monthlyEndMonth) ||
                        !service.yearMonthIsAfter(registration.monthlyEndMonth, registration.monthlyStartMonth))) {
                    errors.push(validationError('monthlyEndMonth'));
                }
            }
            if (service.hasValue(registration.initialAmount)) {
                if (!service.isPositiveInteger(registration.initialAmount)) {
                    errors.push(validationError('initialAmount'));
                }
            }
        }
        if (!service.hasValue(registration.managementFee)) {
            errors.push(validationError('managementFee'));
        }
        if (!service.isPositiveIntegerValue(registration.debitMonthDay, false)) {
            errors.push(validationError('debitMonthDay'));
        }

        return errors;
    }

    service.validateRegistrationCommon = function(registration) {
        var errors = [];

        if (!registration) {
            errors.push(validationError('registration'));
        }
        if (registration.mode !== service.MODE_NEW && registration.mode !== service.MODE_EXISTING){
            errors.push(validationError('mode'));
        }
        if (!registration.ssn || registration.ssn.length === 0) {
            errors.push(validationError('ssn'));
        }
        if (!service.brokerInfoCorrect(registration.broker)) {
            errors.push(validationError('broker'));
        }

        return errors;
    }

    // validation helpers

    service.hasValue = function(val, required=true) {
        return (!required && !val) || !!val;
    }

    service.hasLength = function(val, required=true) {
        return (!required && !val) || (!!val && val.length > 0);
    }

    service.yearMonthCorrectFormat = function(yearMonth, required=true) {
        if (!required && (!yearMonth || yearMonth.length === 0)) {
            return true;
        } else {
            // check that we have a value, it is in the correct expected pattern and that the month value is valid
            return !!yearMonth && new RegExp(/^[0-9]{4}-[01][0-9]$/).test(yearMonth) && parseInt(yearMonth.substring(5,7)) > 0 && parseInt(yearMonth.substring(5,7)) < 13;
        }
    }

    service.yearMonthOnOrAfterCurrentMonth = function(yearMonth) {
        if (!!yearMonth && service.yearMonthCorrectFormat(yearMonth, true)) {
            var c = new Date();
            var y = parseInt(yearMonth.substring(0,4));
            var m = parseInt(yearMonth.substring(5,7));
            return y > c.getFullYear() || (y === c.getFullYear() && m >= c.getMonth() + 1);
        }
        return false;
    }

    service.yearMonthIsAfter = function(yearMonth, yearMonth2) {
        if (!!yearMonth && service.yearMonthCorrectFormat(yearMonth, true) && service.yearMonthCorrectFormat(yearMonth2, true)) {
            var y = parseInt(yearMonth.substring(0,4));
            var m = parseInt(yearMonth.substring(5,7));
            var y2 = parseInt(yearMonth2.substring(0,4));
            var m2 = parseInt(yearMonth2.substring(5,7));
            return y > y2 || (y === y2 && m > m2);
        }
        return false;
    }

    service.isPositiveIntegerAboveOrEqualToMinValue = function(str, minValue, required=true){
        if (!required && (!str || str.length)) {
            return true;
        } else if (!!str && str.length) {
            var n = Math.floor(Number(str));
            //Removing leading 0
            while (str && str.charAt(0) === '0') {
                str = str.substring(1);
            }
            return String(n) === str && n >= minValue;
        } else {
            return false;
        }
    }

    service.isPositiveInteger = function(str, required=true) {
        return service.isPositiveIntegerAboveOrEqualToMinValue(str, 0, required);
    }

    service.isPositiveIntegerValue = function(int, required=true){
        return (!required && !int) || int > 0;
    }

    service.bankInfoCorrect = function(bank, required=true) {
        // weirdly enough we allow undefined bank id
        return (!required && !bank) || (!!bank && bank.name && bank.name.length);
    }

    service.bankAccountClearingNumberCorrect = function(bankAccountClearingNumber, required=true) {
        return (!required && !bankAccountClearingNumber) || (bankAccountClearingNumber && new RegExp(/^[0-9]{4}$/).test(bankAccountClearingNumber));
    }

    service.bankAccountNumberCorrect = function(bankAccountNumber, required= true) {
        return (!required && !bankAccountNumber) || (bankAccountNumber && new RegExp(/^[0-9]{1,12}$/).test(bankAccountNumber));
    }

    service.depositTypeDataCorrect = function (depositType, required=true) {
        return (!required && !depositType) || (!!depositType && depositType.id && depositType.id.length > 0 && depositType.displayName && depositType.displayName.length > 0);
    }

    service.brokerInfoCorrect = function (broker, required=true) {
        return (!required && !broker) || (!!broker && broker.name && broker.name.length > 0 && broker.code && broker.code.length > 0);
    }

    // request mappers

    function mapRegistrationAndPortfolioManagementRequest(registration, signatureWithToken, signatureReference) {
        return {
            registration: mapRegistration(registration, signatureWithToken, signatureReference),
            portfolioManagementRequest: mapPortfolioManagementRequest(registration, signatureWithToken, signatureReference),
            signatureWithToken: signatureWithToken,
            signatureReference: signatureReference
        };
    }

    function mapRegistration(registration, signatureWithToken, signatureReference) {
        return {
            pnr: registration.ssn,
            depositType: registration.depositType.id,
            depositNumber: registration.depositNumber,
            bank: {
                id: registration.bank.id,
                name: registration.bank.name
            },
            bankAccountClearingNumber: registration.bankAccountClearingNumber,
            bankAccountNumber: registration.bankAccountNumber,
            broker: {
                name: registration.broker.name,
                code: registration.broker.code
            },
            compensation: {
                type: "none",
                fixedFee: "0.00",
                dynamicFee: "0.00"
            },
            signatureWithToken: signatureWithToken,
            signatureReference: signatureReference
        };
    }

    function mapPortfolioManagementRequest(registration, signatureWithToken, signatureReference) {
        return {
            personalIdentityNumber: registration.ssn,
            depositNumber: registration.depositNumber,
            depositType: registration.depositType.id,
            portfolioTypeId: registration.portfolio.portfolioTypeId,
            portfolioTypeName: registration.portfolio.portfolioTypeName,
            portfolioTypeInstIntCode: registration.portfolio.portfolioTypeInstIntCode,
            portfolioId: registration.portfolio.portfolioId,
            portfolioName: registration.portfolio.portfolioName,
            monthlyAmount: registration.monthlyAmount ? registration.monthlyAmount : 0,
            monthlyStartMonth: registration.monthlyStartMonth,
            monthlyEndMonth: registration.monthlyEndMonth,
            initialAmount: registration.initialAmount ? registration.initialAmount : 0,
            managementFee: registration.managementFee,
            debitMonthDay: registration.debitMonthDay,
            broker: {
                name: registration.broker.name,
                code: registration.broker.code,
            },
            signatureWithToken: signatureWithToken,
            signatureReference: signatureReference
        };
    }
    return service;
});
