angular.module('maxfonder').controller('DepositRegistrationMaxfonderCtrl', function ($scope, $rootScope, $filter, $translate, $state, $resource, BankIdService, Message, RegistrationService, ConfirmationService, MaxfonderService, $uibModal) {

    var translate = $filter('translate');

    $scope.isTouchDevice = $rootScope.isTouchDevice;
    $scope.registration = RegistrationService.getOrCreateRegistration();
    $scope.submitted = false;
    $scope.radioButtons = {taxOutsideSweden: null, createRegistration: null, signing: null};
    $scope.checkboxes = {confirmCheck: false, identityCheck: false, acceptPersonalDataProcessing: false};
    $scope.depositTypes = filterRegistrationDepositTypes($rootScope.config.maxfonderConfig.depositTypes);
    $scope.brokers = [];
    $scope.validationErrors = undefined;
    $scope.isSigningStarted = false;
    $scope.banks = [];

    $scope.registration.compensation.type = "netto";

    $scope.dynamicFeeOptions = [];

    //Populating bank list
    MaxfonderService.getBanks().$promise.then(function (banks) {
        banks = _.sortBy(banks, 'name');
        banks.push({
            id: undefined,
            name: translate('maxfonder_depotRegistration_unknown_bank')
        });
        $scope.banks = banks;
    });

    // depositTypes
    function filterRegistrationDepositTypes(types) {
        return _.filter(types, function (item) {
            return item.id === 'isk' || item.id === 'fonddepa' || item.id === 'ips';
        });
    }

    // Populating fee list
    MaxfonderService.getFees().$promise.then(function (fees) {
        fees = fees.map(value => value.toString().replace(".",","));
        $scope.dynamicFeeOptions = fees;
    });

    function createBrokerRow(brokerWithCodes) {
        var name = brokerWithCodes.firstName+' '+brokerWithCodes.lastName;
        var brokerRow;
        brokerWithCodes.brokerCodes.forEach(function (code) {
            brokerRow = {name: name, code: code, label: (name + ' (' + code + ')')};
            $scope.brokers.push(brokerRow);
        });
        return brokerRow;
    }

    MaxfonderService.getBrokersAndCodes(function (result) {
        result.forEach(function (brokerWithCodes) {
            var brokerRow = createBrokerRow(brokerWithCodes);
            if (brokerWithCodes.pnr === $scope.user.brokerSsn && brokerWithCodes.brokerCodes.length === 1) {
                $scope.registration.broker = brokerRow;
            }
        });
    });

    $scope.getChoice1Info = function(){
        return translate('maxfonder_depotRegistration_createRegistration_choice1_info');
    };

    $scope.modalInfoBox = function(bodytext) {
        $uibModal.open({
            template:'<div><div class="modal-header" ng-if="header"><button type="button" class="close pull-right" ng-click="$dismiss()" aria-hidden="true">&times;</button><h2 class="modal-title">{{header | translate}}</h2></div><div class="modal-body"><div ng-bind-html="body"></div></div><div class="modal-footer"><button class="btn btn-default" ng-click="$dismiss()">{{\'ps_close\' | translate}}</button></div></div>',
            controller: 'SimpleModalCtrl',
            size: 'lg',
            resolve: {
                header: function () { return "Information"; },
                body: function () { return bodytext; }
            }
        });
    };

    //Sorting depositType list
    $scope.sortedDepositTypes = function () {
        return _.sortBy($scope.depositTypes.map(function (t) {
            t.name = translate('maxfonder_depotRegistration_depotType_' + t.id);
            return t;
        }), function (t) {
            return t.name;
        });
    };

    //Utility functions
    $scope.taxOutsideSwedenDisable = function () {
        return $scope.radioButtons.taxOutsideSweden === 'yes';
    };

    $scope.depositTypeIs = function (type) {
        return $scope.registration.depositType && $scope.registration.depositType.id === type;
    };

    $scope.depotTypeChanged = function (obj) {
        $scope.radioButtons.createRegistration = null;
        if ($scope.depositTypeIs('ips')) {
            $scope.registration.compensation.type = 'none';
        } else {
            $scope.registration.compensation.type = "netto";
        }
    };

    $scope.compensationTypeIs = function(type) {
        return $scope.registration.compensation.type && $scope.registration.compensation.type === type;
    };

    function comma2Dot(value) {
        return value ? value.toString().replace(',', '.') : null;
    };

    function sendError(title, message) {
        $scope.isSigningStarted = false;
        $scope.submitted = false;
        Message.error(title, message, 'sendErr');
    };

    //Signing
    $scope.signingStarted = function () {
        $scope.isSigningStarted = true;
        Message.resolveMessage('sendErr');
        Message.resolveMessage('dataValidationErr');
    };

    $scope.signingFailed = function () {
        sendError('maxfonder_depositRegistration_errTitle', 'maxfonder_depositRegistration_send_err');
    };

    //Send functions
    function submittingRegistration() {
        $scope.submitted = true;
        ConfirmationService.reset();
    }

    $scope.createDepositAndSend = function (signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {
        if ($scope.isReadySign()) {
            submittingRegistration();
            RegistrationService.createDepositAndSend($scope.registration, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId).then(
                function (documentId) {
                    $scope.registration.eSigningSelected = true;
                    $scope.registration.submitted = true;
                    $scope.registration.pdfId = documentId;
                    RegistrationService.saveRegistration($scope.registration);
                    $state.go('maxfonderBekraftelse');
                }, function () {
                    sendError('maxfonder_depositRegistration_errTitle', 'maxfonder_depositRegistration_send_err');
                });
        }
    };

    $scope.createDepositAndGeneratePDFForPrint = function () {
        if ($scope.isReadySign()) {
            submittingRegistration();
            Message.resolveMessage('sendErr');
            RegistrationService.createDepositAndGeneratePDFForPrint($scope.registration).then(
                function (pdfResult) {
                    $scope.registration.pdfResult = pdfResult;
                    $scope.registration.submitted = true;
                    RegistrationService.saveRegistration($scope.registration);
                    $state.go('maxfonderBekraftelse');
                }, function () {
                    sendError('maxfonder_depositRegistration_errPDFTitle', 'maxfonder_depositRegistration_generatePDF_err');
                }
            );
        }
    };

    $scope.abort = function () {
        RegistrationService.deleteRegistration();
        $state.go('maxfonder');
    };

    //Go to fundpurchase
    $scope.goToFundpurchase = function () {
        $scope.registration.pending = true;
        RegistrationService.saveRegistration($scope.registration);
        $state.go('maxfonderPurchase');
    };


    //Validation functions
    $scope.isReady = function () {
        return $scope.radioButtons.taxOutsideSweden === 'no' &&
            isCorrectDepositTypeData() &&
            $scope.bankInfoCorrect() &&
            $scope.brokerInfoCorrect() &&
            $scope.checkboxes.confirmCheck &&
            $scope.registration.compensation.type &&
            $scope.compensationInfoCorrect() &&
            $scope.checkboxes.acceptPersonalDataProcessing &&
            (($scope.radioButtons.createRegistration === 'sign' && $scope.checkboxes.identityCheck) || $scope.radioButtons.createRegistration === 'goToFundpurchase') &&
            (($scope.radioButtons.createRegistration && $scope.radioButtons.createRegistration !== 'sign') || ($scope.radioButtons.createRegistration === 'sign' && $scope.radioButtons.signing));
    };

    $scope.isReadySign = function () {
        return $scope.radioButtons.createRegistration === 'sign' && $scope.isReady();
    };

    $scope.validateForm = function () {
        if ($scope.radioButtons.taxOutsideSweden === 'yes') {
            $scope.validationErrors = undefined;
        } else if(!$scope.isReady()){
            $scope.validationErrors = {
                compensationType:$scope.registration.compensation.type,
                createRegistration: $scope.radioButtons.createRegistration
            };
        }

        if ($scope.validationErrors) {
            Message.error(translate('maxfonder_depotRegistration_formerror_title'), translate('maxfonder_depotRegistration_formerror_message'), 'depotRegistrationError');
        } else {
            Message.resolveMessage('depotRegistrationError');
        }
    };

    var isCorrectDepositTypeData = function () {
        return ($scope.registration.depositType && $scope.registration.depositType.id);
    };

    var executeFullSsnFormatValidation = function (input) {
        // Check valid length & form
        if (!input || input.length > 13 || input.length < 12) {
            return false;
        }

        if (input.length === 12) {
            input = input.slice(0, 8) + '-' + input.slice(8);
        }

        if (!input.match(/^(\d{8})-(\d{4})$/)) {
            return false;
        }

        // Clean input
        input = input.replace('-', '');

        function isValidDate(input) {
            var year = Number(input.slice(0, 4));
            var month = Number(input.slice(4, 6));
            var day = Number(input.slice(6, 8));

            if (year <= 1899) {
                return false;
            }

            //If samordningsnummer
            if (day >= 61 && day <= 91) {
                day -= 60;
            }

            //Creating a date from the input (if a nonexisting date is given for example:
            // 1990-01-32 the constructor will return the date: 1990-02-01)
            var d = new Date(year, month - 1, day, 0, 0, 0, 0);

            //Confirming that the date created match the original input and isn't in the future
            return d.getFullYear() === year && (d.getMonth() + 1) === month && d.getDate() === day && d < Date.now();
        }

        if (!isValidDate(input)) {
            return false;
        }

        input = input.slice(2);
        var sum = 0, numdigits = input.length, parity = numdigits % 2, i, digit;

        // Check luhn algorithm
        for (i = 0; i < numdigits; i = i + 1) {
            digit = parseInt(input.charAt(i));
            if (i % 2 === parity) {
                digit *= 2;
            }
            if (digit > 9) {
                digit -= 9;
            }
            sum += digit;
        }
        return (sum % 10) === 0;
    };

    $scope.bankInfoCorrect = function(){
        return $scope.registration.bank &&
            $scope.registration.bank.name &&
            new RegExp(/^[0-9]{4}$/).test($scope.registration.bankAccountClearingNumber) &&
            new RegExp(/^[0-9]{1,12}$/).test($scope.registration.bankAccountNumber);
    };

    // At least one of the fees must be a number > 0 unless type is 'none'.
    $scope.compensationInfoCorrect = function(){
        if(!$scope.registration.compensation.type){ return false;}
        if($scope.compensationTypeIs('none')){ return true; }

        var isEmptyOrZero = function (input) {
            return (!input || Number(input.replace(",", ".")) === 0);
        };
        var isValidPositive = function (input) {
            if(isEmptyOrZero(input)){
                return false;
            }
            return new RegExp(/^\d+([\.|\,]\d+)?$/).test(input);
        };
        var isValidCompensation = function (input) {
            let value = Number(input.replace(",", "."))
            return  value >= 0.5 && value <= 1.75;
        };
        var isValidPercentage = function (input) {
            if(isValidPositive(input)){
                let value = Number(input.replace(",", "."))
                return  value > 0 && value <= 100;
            }
            return false;
        };

        var dynamic = $scope.registration.compensation.dynamicFee;
        var fixed = $scope.registration.compensation.fixedFee;

        if(isValidPercentage(dynamic) && isValidCompensation(dynamic) && isValidPositive(fixed)) { return true; }
        else if(isValidPercentage(dynamic) && isValidCompensation(dynamic) && isEmptyOrZero(fixed)) { return true; }
        else if(isValidPositive(fixed) && isEmptyOrZero(dynamic)) { return true; }
        return false;
    };

    $scope.brokerInfoCorrect = function () {
        return $scope.registration.broker && $scope.registration.broker.label;
    };

    $scope.closeValidateFormWarning = function () {
        Message.resolveMessage('depotRegistrationError');
        if ($scope.radioButtons.taxOutsideSweden === 'yes' || $scope.isReady()) {
            $scope.validationErrors = undefined;
        }
    };

    //Watches
    $scope.$watchGroup(['radioButtons.taxOutsideSweden', 'registration.broker',
        'checkboxes.confirmCheck', 'checkboxes.identityCheck', 'radioButtons.signing',
        'registration.depositType', 'radioButtons.createRegistration', 'registration.bank',
        'registration.bankAccountClearingNumber', 'registration.bankAccountNumber',
        'registration.compensation'], function () {
        $scope.closeValidateFormWarning();
    });

}).filter('nameAndCodeFilter', function () {
    //Filter that filter on name and code based on a searchFilter
    return function (brokers, searchFilter) {

        if (!searchFilter) {
            return brokers;
        }

        var subString = searchFilter.toLowerCase();

        var partitions = _.partition(brokers, function (b) {
            return b.label.toLowerCase().startsWith(subString);
        });

        var filtered = partitions[0];

        angular.forEach(partitions[1], function (b) {
            if (b.label.toLowerCase().indexOf(subString) !== -1) {
                filtered.push(b);
            }
        });
        return filtered;
    };
});

