angular.module('maxfonder')
    .controller('DiskRegistrationModeSelectionMaxfonderCtrl', function ($scope, $location, $filter, $state, DiskService) {

        $scope.registration = DiskService.createRegistration(null);

        $scope.mode_new = DiskService.MODE_NEW;
        $scope.mode_existing = DiskService.MODE_EXISTING;

        $scope.deposits = null;
        $scope.hasDeposits = false;

        DiskService.getNonDiskDepositsForConversion().$promise.then(function (deposits) {
            $scope.deposits = deposits;
            $scope.hasDeposits = deposits && deposits.length > 0;
        });

        $scope.isReady = function () {
            return $scope.registration.mode === DiskService.MODE_NEW || $scope.registration.mode === DiskService.MODE_EXISTING;
        };

        $scope.proceed = function () {
            if($scope.isReady()) {
                DiskService.saveRegistration($scope.registration);
                if ($scope.registration.mode === $scope.mode_new) {
                    $state.go('maxfonderDiskDepositRegistration');
                } else if ($scope.registration.mode === $scope.mode_existing) {
                    $state.go('maxfonderDiskPortfolioSelection');
                }
            }
        };

        $scope.cancel = function () {
            DiskService.deleteRegistration();
            $state.go('maxfonder');
        };

        $scope.$watch('registration.mode', function () {
          console.log("mode changed to " + $scope.registration.mode);
        });

});
