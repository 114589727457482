angular.module('maxfonder')
    .controller('DiskPortfolioSelectionMaxfonderCtrl', function ($scope, $location, $filter, $state, Message, DiskService) {

        // populate dropdown choices for monthlychargingday
        function createDebitMonthDayList() {
            let arr = [];
            for(var day=1; day <= 28; day++){
                arr.push(day);
            }
            return arr;
        }

        function sendError(title, message) {
            Message.error(title, message, 'sendErr');
        }

        var translate = $filter('translate');

        $scope.VALID_PORTFOLIO_TYPE_CODES = ['ADVISORY-DISC'];

        $scope.registration = DiskService.getSavedRegistration();
        $scope.selected = {deposit: null, portfolio: null, portfolioType: null};
        $scope.checkboxes = {confirmAllDocuments: false, confirmPlatformFee: false};

        $scope.brokers = [];

        $scope.deposits = null;
        $scope.depositLoaded = false;
        $scope.fees = null;
        $scope.feesLoaded = false;
        $scope.debitMonthDayList = createDebitMonthDayList();

        $scope.validationErrors = [];

        $scope.isSigningStarted = false;

        // show error if no existing registration
        $scope.hasRegistration = $scope.registration && $scope.registration.mode;

        // set default deposit day
        $scope.registration.debitMonthDay = 28;

        if ($scope.registration.mode === DiskService.MODE_EXISTING) {
            // load deposits
            DiskService.getNonDiskDepositsForConversion().$promise.then(function (deposits) {
                $scope.depositLoaded = true;
                $scope.deposits = deposits;
            });

            // load brokers
            DiskService.getBrokersAndCodes().$promise.then(function (result) {
                result.forEach(function (brokerWithCodes) {
                    var name = brokerWithCodes.firstName + ' ' + brokerWithCodes.lastName;
                    brokerWithCodes.brokerCodes.forEach(function(code) {
                        var brokerRow = DiskService.mapBrokerRow(name, code);
                        $scope.brokers.push(brokerRow);
                        if ((brokerWithCodes.pnr === $scope.user.brokerSsn && brokerWithCodes.brokerCodes.length === 1)) {
                            $scope.registration.broker = brokerRow;
                        }
                    });
                });
            });
        }

        DiskService.getDiscFees().$promise.then(function (fees) {
            $scope.feesLoaded = true;
            $scope.fees = fees;
        });

        DiskService.getPortfolioTypes().$promise.then(function (types) {
            types = _.sortBy(types, function(type) { return type.portfolioTypeId; });
            $scope.portfoliotypes = types.filter((type) => $scope.VALID_PORTFOLIO_TYPE_CODES.includes(type.instIntCode));
        });

        $scope.clearPortfolioData = function() {
            $scope.selected.portfolioType = null;
            $scope.selected.portfolio = null;
        };

        $scope.formatFeeValue = function(value) {
            return value.toString();
        }

        $scope.formatFeeDisplayValue = function(value) {
            return value.toString().replace(".",",");
        }

        $scope.$watch('selected.deposit', function () {
            var reg = $scope.registration;
            if ($scope.selected.deposit != null && reg.mode === DiskService.MODE_EXISTING) {
                reg.depositNumber =  $scope.selected.deposit.depositNumber;
                reg.depositType = {
                    id: $scope.selected.deposit.depositType,
                    displayName: $scope.selected.deposit.depositType // TODO: remove dependency of this in all reqs
                };
            }
        });

        $scope.$watch('selected.portfolioType', function () {
            if ( $scope.selected.portfolioType != null ) {
                DiskService.getPortfoliosOfType($scope.selected.portfolioType.portfolioTypeId).$promise.then(function (portfolios) {
                    $scope.portfolios = portfolios;
                });
            }
        });

        $scope.$watch('selected.portfolio', function () {
            var reg = $scope.registration;
            if ($scope.selected.portfolio) {
                reg.portfolio.portfolioId = $scope.selected.portfolio.portfolioId;
                reg.portfolio.portfolioName = $scope.selected.portfolio.name;
                reg.portfolio.portfolioTypeId = $scope.selected.portfolioType.portfolioTypeId;
                reg.portfolio.portfolioTypeName = $scope.selected.portfolioType.name;
                reg.portfolio.portfolioTypeInstIntCode = $scope.selected.portfolioType.instIntCode;
            } else {
                reg.portfolio = {};
            }
        });

        $scope.$watch('registration.monthlyAmount', function () {
            var reg = $scope.registration;
            if (!reg.monthlyAmount || !reg.monthlyAmount.length){
                reg.monthlyStartMonth = null;
                reg.monthlyEndMonth = null;
            }
        });

        $scope.validateAll = function () {
            Message.resolveMessage('validationError');
            $scope.validationErrors = DiskService.validateRegistrationCommon($scope.registration);
            $scope.validationErrors = $scope.validationErrors.concat(DiskService.validateDepositData($scope.registration));
            $scope.validationErrors = $scope.validationErrors.concat(DiskService.validatePortfolioManagementRegistrationData($scope.registration));

            // validate limits for selected portfoliotype

            if ($scope.registration.monthlyAmount) {
                if ($scope.selected.portfolioType && $scope.selected.portfolioType.minMonthlyInvestment && $scope.selected.portfolioType.minMonthlyInvestment > 0) {
                    if (!DiskService.isPositiveIntegerAboveOrEqualToMinValue($scope.registration.monthlyAmount, $scope.selected.portfolioType.minMonthlyInvestment, true)) {
                        $scope.validationErrors.push(DiskService.newValidationError('monthlyAmount', 'tooLow"'));
                    }
                }
            }
            if ($scope.registration.initialAmount) {
                if ($scope.selected.portfolioType && $scope.selected.portfolioType.minInvestment && $scope.selected.portfolioType.minInvestment > 0) {
                    if (!DiskService.isPositiveIntegerAboveOrEqualToMinValue($scope.registration.initialAmount, $scope.selected.portfolioType.minInvestment, true)) {
                        $scope.validationErrors.push(DiskService.newValidationError('initialAmount', 'tooLow'));
                    }
                }
            }

            if (!$scope.checkboxes.confirmPlatformFee) {
                $scope.validationErrors.push(DiskService.newValidationError('confirmPlatformFee'));
            }
            if (!$scope.checkboxes.confirmAllDocuments) {
                $scope.validationErrors.push(DiskService.newValidationError('confirmAllDocuments'));
            }

            if ($scope.hasValidationErrors()) {
                Message.error(translate('maxfonder_disk_validationError_errTitle'), translate('maxfonder_disk_validationError_errMessage'), 'validationError');
            } else {
                Message.resolveMessage('validationError');
            }

            console.log($scope.validationErrors);
        };

        $scope.hasValidationErrors = function () {
            return $scope.validationErrors && $scope.validationErrors.length > 0;
        };

        $scope.hasValidationErrorForField = function (fieldName, errorType=null) {
            return DiskService.getValidationErrorForField($scope.validationErrors, fieldName, errorType);
        }

        $scope.hasNoValue = function(value) {
            var number = Number(value);
            return Number(value) === 0 || _.isNaN(number);
        };

        $scope.backEnabled = function() {
            return $scope.registration.mode === DiskService.MODE_NEW;
        };

        $scope.back = function () {
            $state.go('maxfonderDiskDepositRegistration');
        };

        $scope.cancel = function () {
            $state.go('maxfonder');
        };

        $scope.hasSetValue = function(value) {
            return value !== null && value !== undefined && value !== '';
        }

        $scope.isReady = function() {
            return !$scope.hasValidationErrors();
        }

        $scope.isReadySign = function() {
            $scope.validateAll();
            return $scope.isReady();
        }

        $scope.getSignContext = function() {
            if ($scope.registration.mode === DiskService.MODE_NEW) {
                return "MAXFONDER_DISK_ANSOKAN_NY_DEPA";
            } else if ($scope.registration.mode === DiskService.MODE_EXISTING) {
                return "MAXFONDER_DISK_ANSOKAN_EXISTERANDE_DEPA";
            } else {
                throw "Bad registration mode";
            }
        }

        $scope.getNextButtonText = function() {
            if ($scope.registration.mode === DiskService.MODE_NEW) {
                return "maxfonder_disk_portfolioselection_next_new";
            } else if ($scope.registration.mode === DiskService.MODE_EXISTING) {
                return "maxfonder_disk_portfolioselection_next_existing";
            } else {
                throw "Bad registration mode";
            }
        }

        $scope.createSignDataInput = function() {
            var reg = $scope.registration;
            if (reg.mode === DiskService.MODE_NEW) {
                var req = {
                    registrationVisibleDataInput: {
                        depositType: reg.depositType.id,
                        bank: reg.bank,
                        bankAccountClearingNumber: reg.bankAccountClearingNumber,
                        bankAccountNumber: reg.bankAccountNumber
                    },
                    portfolioManagementRequestVisibleDataInput: {
                        depositType: reg.depositType.id,
                        portfolioName: reg.portfolio.portfolioName,
                        monthlyAmount: (reg.monthlyAmount ? reg.monthlyAmount : 0),
                        initialAmount: (reg.initialAmount ? reg.initialAmount : 0),
                        managementFee: reg.managementFee
                    }
                }
                return req;
            } else if (reg.mode === DiskService.MODE_EXISTING) {
                var req = {
                    depositNumber: reg.depositNumber,
                    portfolioName: reg.portfolio.portfolioName,
                    monthlyAmount: (reg.monthlyAmount ? reg.monthlyAmount : 0),
                    initialAmount: (reg.initialAmount ? reg.initialAmount : 0),
                    managementFee: reg.managementFee
                }
                return req;
            } else {
                throw "Bad registration";
            }
        }

        $scope.signingStarted = function() {
            $scope.isSigningStarted = true;
            console.log("Signing started");
        }

        $scope.signingFailed = function() {
            $scope.isSigningStarted = false;
            console.log("Signing failed");
        }

        $scope.sendSigned = function(signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {
            var reg = $scope.registration;
            console.log("sendSigned");
            DiskService.send(reg, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId).then(
                function (documentId) {
                    reg.pdfId = documentId;
                    DiskService.saveRegistration(reg);
                    $state.go('maxfonderDiskConfirmation');
                }, function (error) {
                    console.log("Error sending registration");
                    console.log(error);
                    sendError('maxfonder_disk_errTitle', 'maxfonder_disk_send_err');
                });
        }

        $scope.translateWithParams = function(key, params){
            return $filter('translate')(key, params);
        }

});
